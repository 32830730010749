import {
    ElMessage,
} from 'element-plus'
//导入方法
const orderNumbers = new Array()
const importExcel = function(obj, tabelName, title = '默认标题', ) {
    //读取数据修正
    let promise = new Promise(function(resolve, reject) {
        const excelname = obj.name
        const reader = new FileReader()
        const _this = this
        reader.readAsArrayBuffer(obj)
        reader.onload = function() {
            const buffer = reader.result;
            const bytes = new Uint8Array(buffer)
            const length = bytes.byteLength
            let binary = ""
            for (let i = 0; i < length; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            const XLSX = require("xlsx");
            const wb = XLSX.read(binary, {
                type: "binary",
            })
            const outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
            this.data = [...outdata];
            const arr = {
                correct: [],
                error: [],
                errorMessage: [],
            };
            this.data.map((vs) => {
                const obj = {};
                tabelName.tableHead.forEach((value) => {
                    let ty = value.type
                    if (ty.includes('date')) {
                        // 修正日期
                        obj[value.property] = isDate(vs[value.label]) ? vs[value
                            .label] : toDate(vs[value.label])
                    } else if (value.type == 'number') {
                        // 修正数字
                        obj[value.property] = toMoney(vs[value.label])
                    } else if (value.type == 'select') {
                        if (valueInArray(vs[value.label], value.option)) {
                            obj[value.property] = vs[value.label]
                        } else {
                            obj[value.property] =
                                `无效${value.label}:${vs[value.label]}`
                        }
                    } else {
                        obj[value.property] = vs[value.label]
                    }
                })
                if (title == '客户列表' || title == '人工录入') { //是否过滤数数据
                    tabelName.name = title
                    booleanJudge(obj, tabelName).then(b => {
                        //correct:保留，error：异常，discard：删除
                        if (b.boolean === 'correct') {
                            arr.correct.push(b.correct);
                        }
                        if (b.boolean === 'error') {
                            arr.error.push(b.error);
                            arr.errorMessage.push(b.errorMessage);
                        }
                    })
                } else {
                    arr.correct.push(obj);
                }
            });
            //回调函数
            resolve(arr);
            //_this.tableData换成你要导入的table表
        }
    });
    return promise;
}
const booleanJudge = function(cace, tabelName) { //判断
    let promise = new Promise(function(resolve, reject) {
        const ves = {
            errorMessage: {}, //错误的原因
            boolean: 'correct',
            correct: {}, //正确的数
            error: {}, //错误的数据
            discard: {} //删除的数据
        }
        let brands = new Array()
        let readModifys = {}
        let casevle = {}
        switch (tabelName.name) { // cace.order_status != '等待买家付款' &&
            case '客户列表':
                cace.Add_Time = useSplit(cace.Add_Time, ' ', 0)
                cace.phone_number = spaces(cace.phone_number)
                tabelName.tableHead.forEach(v => { //获取匹配对象obj
                    if (v.property === 'Add_Method') {
                        readModifys = v.readModify
                    }
                })
                cace.Add_Method = useMatching(cace.Add_Method, readModifys) //匹配对象obj
                ves.correct = cace
                resolve(ves);
                break;
            case '人工录入':
                cace.trade_date = toDate(cace.trade_date)
                cace.bill_volume = toIsUndefined(cace.bill_volume, 0)
                cace.mocv = toIsUndefined(cace.mocv, 0)
                cace.brand_recharge_amount = toIsUndefined(cace.brand_recharge_amount, 0)
                cace.nomoc = toIsUndefined(cace.nomoc, 0)
                cace.AI_ocv = toIsUndefined(cace.AI_ocv, 0)
                cace.noto = toIsUndefined(cace.noto, 0)
                cace.total_online_orders = toIsUndefined(cace.total_online_orders, 0)
                ves.correct = cace
                resolve(ves);
                break;
            default:
                ves.boolean = 'error'
                resolve(ves);
                break;
        }
    })
    return promise;
}

const deepCopyObj = function(e) { //深拷贝obj对象
    let n = {}
    for (let key in e) {
        n[key] = e[key]
    }
    return n
}

const spaces = function(e) { //去除首尾空格
    if (e === undefined) {
        return e
    }
    if ((typeof e) === 'number') {
        e = e.toString()
    }
    return e.replace(/^\s*|\s*$/g, "")
}

const toStrings = function(e) { //强制字符串转化
    if (e === undefined) {
        return e
    }
    if ((typeof e) != 'string') {
        return e.toString()
    }
    return e
}

const toIsUndefined = function(e, v) { //判空补充
    if (v === undefined) {
        console.log(e + '没有补充值')
        return e
    }
    if (e === undefined) {
        return v
    }
    return e
}
const toMoney = function(e, v) { //金额判空补0，修正句号为英文点
    if (e === undefined) {
        return 0
    }
    if (isNaN(Number(e))) {
        e.replace(/\s*/g, ""); //去除全部空格  
        if (e.indexOf('。') != -1) {
            return Number(e.replace("。", "."))
        }
        return e
    }
    return Number(e)
}
const toDate = function(e) { //日期格式修正excel'yy/mm/dd'
    if (e === undefined) {
        return '空值'
    }
    if (e.toString().indexOf(".") != -1) {
        e = parseInt(e) //取整
    }
    let time = new Date((e - 25567) * 24 * 3600000 -
        5 * 60 * 1000 -
        43 * 1000 -
        24 * 3600000 - 8 * 3600000);
    let year = time.getFullYear() + ''
    let month = time.getMonth() + 1 + ''
    let date = time.getDate() + ''
    if (isNaN(year)) {
        return e
    }
    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
}
const useSplit = function(e, v, n) { //拆分数据，为空返回本值，不包含拆分字符返回本身
    if (e === undefined) {
        return '空值'
    }
    if (v === undefined) {
        console.log(e + '没有拆分值')
        return e
    }
    if (n === undefined) {
        console.log(e + '没有拆分数')
        return e
    }
    if (e.indexOf(v) === -1) {
        return e
    }
    if (e.split(v)[n] === undefined) {
        console.log(e + '没有拆分结果')
        return e
    }
    return e.split(v)[n]
}
const useMatching = function(e, v) { //匹配替换字符串
    if (e === undefined) {
        return e
    }
    if (v === undefined) {
        console.log(e + '没有匹配数组')
        return e
    }
    if (v[e]) {
        return v[e]
    }
    return e + '-未知'
}
const useForReplace = function(e, v) { //循环去除特定文字
    let promise = new Promise(function(resolve, reject) {
        if (e === undefined) {
            resolve(e);
        }
        if (v === undefined) {
            console.log(e + '没有去除数据数组')
            resolve(e);
        }
        if (!Array.isArray(v)) {
            console.log(e + '去除数据不是数组')
            resolve(e);
        }
        v.forEach(value => {
            e.replace(value, "")
        })
        resolve(e);
    })
    return promise;
}
const isDate = function(v) { //判断是否为日期
    if (v === undefined) {
        return false
    }
    if ((new Date(v).getDate() != v.toString().substring(v.length - 2).replace(/\//g, ''))) {
        //验证成功
        return false
    }
    return true
}
const valueInArray = function(e, v) { //判断数据是否存在于数组中
    if (e === undefined) {
        return false
    }
    if (v === undefined) {
        console.log(e + '没有判断存在数据数组')
        return false
    }
    if (!Array.isArray(v)) {
        console.log(e + '判断存在数据不是数组')
        return false
    }
    if (v.indexOf(e) === -1) {
        return false
    }
    return true
}

export {
    importExcel,
    booleanJudge
};